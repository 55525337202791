import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ErrorLoggingInit from './errorLogging';
import AnalyticsInit from './analytics';
import IntercomInit from './intercom';
import './fileUploads';

ErrorLoggingInit(
  window.CONFIGURATION.SENTRY_DSN,
  window.CONFIGURATION.USER,
  window.CONFIGURATION.ENVIRONMENT
);
AnalyticsInit(window.CONFIGURATION.ANALYTICS_ID, window.CONFIGURATION.USER);
IntercomInit(window.CONFIGURATION.INTERCOM_ID, window.CONFIGURATION.USER);

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV !== 'production') {
  document.querySelector('body').classList.add('DEV');
}
